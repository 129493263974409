import React, { Component } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
    Info, AccessTime, Groups, Public,
    EventNote, CalendarMonth, LooksOne,
    LooksTwo, Looks3, Contacts, Description
} from '@mui/icons-material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

interface InfoTableProps
{

}


class InfoTable extends Component<{ }, InfoTableProps>
{
    constructor(props: any)
    {
        super(props);
    }

    render()
    {

        return (
            <div className="info-table">
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead className={"info-table-header"}>
                            <TableRow>
                                <TableCell className={"info-cell"}>
                                    <Typography className="register-now-label" variant={"h5"}>
                                        Information
                                    </Typography>
                                </TableCell>
                                <TableCell className={"info-cell"} align="right">&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell className={"info-cell icon"}>
                                    <div>
                                        <AccessTime/>
                                        <div>When</div>
                                    </div>
                                </TableCell>
                                <TableCell className={"info-cell"} align="right">
                                    6-7 December, 2024
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={"info-cell icon"}>
                                    <div>
                                        <Public/>
                                        <div>Where</div>
                                    </div>
                                </TableCell>
                                <TableCell className={"info-cell"} align="right">Hypixel Bedwars Lobby</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={"info-cell icon"}>
                                    <div>
                                        <Groups/>
                                        <div>Teams</div>
                                    </div>
                                </TableCell>
                                <TableCell className={"info-cell"} align="right">2v2</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={"info-cell icon"}>
                                    <div>
                                        <EventNote/>
                                        <div>Maps</div>
                                    </div>
                                </TableCell>
                                <TableCell className={"info-cell"} align="right">
                                    Teams will be placed across from each other on 4v4v4v4 maps
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={"info-cell icon"}>
                                    <div>
                                        <CalendarMonth/>
                                        <div>Tournament Format</div>
                                    </div>
                                </TableCell>
                                <TableCell className={"info-cell"} align="right">Double Elimination w/Best 2 out of 3 for finals and semi-finals</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={"info-cell icon gold"}>
                                    <div>
                                        <LooksOne/>
                                        <div>1st Place Prize</div>
                                    </div>
                                </TableCell>
                                <TableCell className={"info-cell"} align="right">$25 Gift Card</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={"info-cell icon gold"}>
                                    <div>
                                        <LooksTwo/>
                                        <div>2nd Place Prize</div>
                                    </div>
                                </TableCell>
                                <TableCell className={"info-cell"} align="right">Hypixel Rank upgrade</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={"info-cell icon gold"}>
                                    <div>
                                        <Looks3/>
                                        <div>3rd Place Prize</div>
                                    </div>
                                </TableCell>
                                <TableCell className={"info-cell"} align="right">Hypixel Rank upgrade</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={"info-cell icon discord"}>
                                    <div>
                                        <FontAwesomeIcon icon={faDiscord}/>
                                        <div>Discord Contact</div>
                                    </div>
                                </TableCell>
                                <TableCell className={"info-cell"} align="right">
                                    <Link href={"https://discord.gg/jY9uW28Yun"} variant={"body2"}>
                                        Join Kymerian Bedwars Tournament Discord Server
                                    </Link>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }

}

export default InfoTable;





