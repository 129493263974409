import React, { Component } from 'react';
import CountDown from "./CountDown";
import InfoTable from "./InfoTable";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";


interface HomeTabPaneProps {}

class HomeTabPane extends Component<{}, HomeTabPaneProps>
{
    constructor(props: any) {
        super(props);
    }

    render()
    {
        return (
            <div className={"home-tab-pane"}>
                <div className="logo-center">
                    <div className="count-down">
                        <img src="/main-logo.png" alt=""/>
                    </div>
                </div>

                <InfoTable/>
                <div className={"register-now-container"} >
                    <Typography className="register-now-label" variant={"h4"}>
                        Ready to compete?
                    </Typography>
                    <Button className={"register-button"} variant={"contained"} size={"large"} href={"https://forms.gle/kTgC3iHb2VgVS2q7A"} target={"about"}>
                        Register Now
                    </Button>
                </div>
            </div>
        );
    }
}

export default HomeTabPane;



