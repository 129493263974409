import React, {Component} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Description, Cancel, Error, Check, Close} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

interface RulesTabPaneProps {
}


class RulesTabPane extends Component<{}, RulesTabPaneProps>
{
    constructor(props: any)
    {
        super(props);
    }

    render()
    {
        return (
            <div className={"rules-tab-pane info-table"}>
                <Typography className="register-now-label" variant={"h3"}>
                    <Description/>
                    Rules
                </Typography>
                <Typography className="rules-description-label" variant={"body1"}>
                    <b>Failure to follow the rules will result in your team’s disqualification from the tournament. </b>
                    If a rule is violated, your team will forfeit the current game and be prohibited from advancing in
                    the
                    tournament. No exceptions will be made. All teams are expected to <b>READ THE RULES
                    THOROUGHLY.</b> Excuses
                    like, 'I forgot,' or 'I didn’t read the rules,' will not be accepted."
                </Typography>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead className={"info-table-header"}>
                            <TableRow>
                                <TableCell className={"info-cell"}>#</TableCell>
                                <TableCell className={"info-cell"} align="right">Rule</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell className={"info-cell"}>
                                    <div>1.</div>
                                </TableCell>
                                <TableCell className={"info-cell"} align="right">
                                    <div className="rule-icon green">
                                        <Check/> Potions AFTER bed break.
                                    </div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={"info-cell"}>
                                    <div>2.</div>
                                </TableCell>
                                <TableCell className={"info-cell"} align="right">
                                    <div className="rule-icon green">
                                        <Check/>Ender pearls AFTER bed break.
                                    </div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={"info-cell"}>
                                    <div>4.</div>
                                </TableCell>
                                <TableCell className={"info-cell"} align="right">
                                    <div className="rule-icon">
                                        <Close/>NO water outside your OWN base.
                                    </div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={"info-cell"}>
                                    <div>5.</div>
                                </TableCell>
                                <TableCell className={"info-cell"} align="right">
                                    <div className="rule-icon">
                                        <Close/>NO obsidian.
                                    </div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={"info-cell"}>
                                    <div>6.</div>
                                </TableCell>
                                <TableCell className={"info-cell"} align="right">
                                    <div className="rule-icon">
                                        <Close/>NO bows.
                                    </div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={"info-cell"}>
                                    <div>7.</div>
                                </TableCell>
                                <TableCell className={"info-cell"} align="right">
                                    <div className="rule-icon">
                                        <Close/>NO knock-back sticks.
                                    </div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={"info-cell"}>
                                    <div>8.</div>
                                </TableCell>
                                <TableCell className={"info-cell"} align="right">
                                    <div className="rule-icon">
                                        <Close/>NO rotating items.
                                    </div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={"info-cell"}>
                                    <div>9.</div>
                                </TableCell>
                                <TableCell className={"info-cell"} align="right">
                                    <div className="rule-icon">
                                        <Close/>NO viewing the twitch stream while in game.
                                    </div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={"info-cell"}>
                                    <div>10.</div>
                                </TableCell>
                                <TableCell className={"info-cell"} align="right">
                                    <div className="rule-icon">
                                        <Close/>NO hacks or cheats.
                                    </div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={"info-cell"}>
                                    <div>11.</div>
                                </TableCell>
                                <TableCell className={"info-cell"} align="right">
                                    <div className="rule-icon">
                                        <Close/> NO texture packs that make particles from invisible players visible.
                                    </div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={"info-cell"}>
                                    <div>11.</div>
                                </TableCell>
                                <TableCell className={"info-cell"} align="right">
                                    <div className="rule-icon green">
                                        <Check/>TNT.
                                    </div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={"info-cell"}>
                                    <div>11.</div>
                                </TableCell>
                                <TableCell className={"info-cell"} align="right">
                                    <div className="rule-icon green">
                                        <Check/>Ladders.
                                    </div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={"info-cell"}>
                                    <div>11.</div>
                                </TableCell>
                                <TableCell className={"info-cell"} align="right">
                                    <div className="rule-icon green">
                                        <Check/> Bridge eggs.
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className={"register-now-container"}>
                    <Typography className="register-now-label" variant={"h4"}>
                        Ready to compete?
                    </Typography>
                    <Button className={"register-button"} variant={"contained"} size={"large"}
                            href={"https://forms.gle/kTgC3iHb2VgVS2q7A"} target={"about"}>
                        Register Now
                    </Button>
                </div>
            </div>
        );
    }
}

export default RulesTabPane;