import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import CountDown from "./CountDown";
import InfoTable from "./InfoTable";
import HomeTabPane from "./HomeTabPane";
import RulesTabPane from "./RulesTabPane";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Button from "@mui/material/Button";


interface AppState
{
    tab: string;
}

class App extends Component<{}, AppState>
{
    constructor(props: any)
    {
        super(props);
        this.state = {
            tab: "0",
        };
    }

    render()
    {
        const tab = this.state.tab;

        return (
            <div id="app">
                <div className="tab-bar">
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <Tabs value={tab} centered={true} onChange={(e, v) => this.setState({tab: v})}>
                            <Tab label="Home" value={"0"} />
                            <Tab label="Rules" value={"1"} />
                            {/*<Tab label="Info" value={"2"} />*/}
                        </Tabs>
                    </Box>
                </div>
                {this.getCurrentTabPane()}
                <div className="footer">

                </div>
            </div>
        );
    }

    getCurrentTabPane()
    {
        const tab = this.state.tab;
        switch (tab)
        {
            case "0":
                return <HomeTabPane/>;
            case "1":
                return <RulesTabPane/>;
            default:
                return (<div></div>);
        }
    }
}


export default App;
